import Blockchain from '../icons/Blockchain';
import Consulting from '../icons/Consulting';

export interface ServiceModel {
  id: string;
  image: JSX.Element;
  title: string;
  desc: string;
}

export const servicesData: ServiceModel[] = [
  {
    id: 's1',
    image: <Blockchain />,
    title: 'Blockchain Development',
    desc: 'Smart contract development, decentralized application (DApp) development, and blockchain integration.',
  },
  {
    id: 's2',
    image: <Consulting />,
    title: 'Consulting Services',
    desc: 'Monthly retainer-based consultancy for blockchain companies seeking strategic advice, project management, marketing support and technical expertise.',
  },
];
