import classes from './Project.module.scss';
import { ProjectModel } from './Project.util';

const Project = ({ project }: { project: ProjectModel }): JSX.Element => {
  return (
    <li className={classes['item']}>
      <div className={classes['icon-container']}>{project.image}</div>

      <div className={classes['title']}>{project.title}</div>

      <p className={classes['desc']}>{project.desc}</p>

      <ul className={classes['areas']}>
        {project.areas.map((area, idx) => (
          <li key={idx} className={classes['area']}>
            {area}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default Project;
