import classes from './BackgroundGrid.module.scss';

const BackgroundGrid = (): JSX.Element => {
  return (
    <>
      <svg
        className={classes['svg']}
        width='1440'
        height='1440'
        viewBox='0 0 1440 1440'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_2009_4)'>
          <g clipPath='url(#clip1_2009_4)'>
            <line x1='48.5' x2='48.5' y2='1440' stroke='#43C2CD' />
            <line x1='144.5' x2='144.5' y2='1440' stroke='#43C2CD' />
            <line x1='240.5' x2='240.5' y2='1440' stroke='#43C2CD' />
            <line x1='336.5' x2='336.5' y2='1440' stroke='#43C2CD' />
            <line x1='432.5' x2='432.5' y2='1440' stroke='#43C2CD' />
            <line x1='528.5' x2='528.5' y2='1440' stroke='#43C2CD' />
            <line x1='624.5' x2='624.5' y2='1440' stroke='#43C2CD' />
            <line x1='720.5' x2='720.5' y2='1440' stroke='#43C2CD' />
            <line x1='816.5' x2='816.5' y2='1440' stroke='#43C2CD' />
            <line x1='912.5' x2='912.5' y2='1440' stroke='#43C2CD' />
            <line x1='1008.5' x2='1008.5' y2='1440' stroke='#43C2CD' />
            <line x1='1104.5' x2='1104.5' y2='1440' stroke='#43C2CD' />
            <line x1='1200.5' x2='1200.5' y2='1440' stroke='#43C2CD' />
            <line x1='1296.5' x2='1296.5' y2='1440' stroke='#43C2CD' />
            <line x1='1392.5' x2='1392.5' y2='1440' stroke='#43C2CD' />
          </g>
          <g clipPath='url(#clip2_2009_4)'>
            <line x1='-240' y1='95.5' x2='1680' y2='95.5' stroke='#43C2CD' />
            <line x1='-240' y1='191.5' x2='1680' y2='191.5' stroke='#43C2CD' />
            <line x1='-240' y1='287.5' x2='1680' y2='287.5' stroke='#43C2CD' />
            <line x1='-240' y1='383.5' x2='1680' y2='383.5' stroke='#43C2CD' />
            <line x1='-240' y1='479.5' x2='1680' y2='479.5' stroke='#43C2CD' />
            <line x1='-240' y1='575.5' x2='1680' y2='575.5' stroke='#43C2CD' />
            <line x1='-240' y1='671.5' x2='1680' y2='671.5' stroke='#43C2CD' />
            <line x1='-240' y1='767.5' x2='1680' y2='767.5' stroke='#43C2CD' />
            <line x1='-240' y1='863.5' x2='1680' y2='863.5' stroke='#43C2CD' />
            <line x1='-240' y1='959.5' x2='1680' y2='959.5' stroke='#43C2CD' />
            <line
              x1='-240'
              y1='1055.5'
              x2='1680'
              y2='1055.5'
              stroke='#43C2CD'
            />
            <line
              x1='-240'
              y1='1151.5'
              x2='1680'
              y2='1151.5'
              stroke='#43C2CD'
            />
            <line
              x1='-240'
              y1='1247.5'
              x2='1680'
              y2='1247.5'
              stroke='#43C2CD'
            />
            <line
              x1='-240'
              y1='1343.5'
              x2='1680'
              y2='1343.5'
              stroke='#43C2CD'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_2009_4'>
            <rect
              width='1920'
              height='1440'
              fill='white'
              transform='translate(-240)'
            />
          </clipPath>
          <clipPath id='clip1_2009_4'>
            <rect
              width='1920'
              height='1440'
              fill='white'
              transform='translate(-240)'
            />
          </clipPath>
          <clipPath id='clip2_2009_4'>
            <rect
              width='1920'
              height='1440'
              fill='white'
              transform='translate(-240)'
            />
          </clipPath>
        </defs>
      </svg>
      <div className={classes['background-color']}></div>
    </>
  );
};

export default BackgroundGrid;
