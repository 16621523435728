import BackgroundGrid from '../../../../components/background-grid/BackgroundGrid';
import Button from '../../../../components/button/Button';
import AptosMonkey from '../../../../components/icons/AptosMonkey';
import GuiInu from '../../../../components/icons/GuiInu';
import classes from './Landing.module.scss';

const LandingSection = (): JSX.Element => {
  return (
    <section className={classes['section']}>
      <BackgroundGrid />

      <div className={classes['content']}>
        <h3 className={classes['subtitle']}>
          Get top of the line blockchain development and consulting service.
        </h3>
        <h1 className={classes['title']}>
          The premier <span className={classes['mint']}>blockchain</span>{' '}
          service providers.
        </h1>

        <div className={classes['btn-container']}>
          <Button />
        </div>

        <div className={classes['claim']}>
          We’ve worked with top blockchain companies
        </div>

        <ul className={classes['blockchain-companies']}>
          <li className={classes['blockchain-company']}>
            <div className={classes['image-container']}>
              <GuiInu />
            </div>

            <title>Gui Inu</title>
          </li>

          <li className={classes['blockchain-company']}>
            <div className={classes['image-container']}>
              <AptosMonkey />
            </div>

            <title>Aptos Monkeys</title>
          </li>
        </ul>

        <h2 className={classes['goal']}>
          Navigating the blockchain space is{' '}
          <span className={classes['mint']}>challenging</span>. Let’s make it{' '}
          <span className={classes['mint']}>easier</span>.
        </h2>
      </div>
    </section>
  );
};

export default LandingSection;
