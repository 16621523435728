import ActionButton from '../../../../components/action-button/ActionButton';
import ProjectList from '../../../../components/project/ProjectList';
import StartWithUs from '../../../../components/start-with-us/StartWithUs';
import classes from './Bottom.module.scss';

const BottomSection = (): JSX.Element => {
  return (
    <section className={classes['section']}>
      <ActionButton>Portfolio</ActionButton>

      <div className={classes['title']}>
        Developing several <span className={classes['mint']}>IT</span> and{' '}
        <span className={classes['mint']}>blockchain</span> projects over the
        years, these are a few:
      </div>

      <ProjectList />

      <StartWithUs />
    </section>
  );
};

export default BottomSection;
