import classes from './Service.module.scss';
import { ServiceModel } from './service.util';

const Service = ({ service }: { service: ServiceModel }): JSX.Element => {
  return (
    <li className={classes['item']}>
      <div className={classes['icon-container']}>{service.image}</div>

      <h2 className={classes['title']}>{service.title}</h2>

      <p className={classes['desc']}>{service.desc}</p>
    </li>
  );
};

export default Service;
