import Project from './Project';
import { projectsData } from './Project.util';
import classes from './ProjectList.module.scss';

const ProjectList = (): JSX.Element => {
  return (
    <ul className={classes['list']}>
      {projectsData.map((project) => (
        <Project key={project.id} project={project} />
      ))}
    </ul>
  );
};

export default ProjectList;
