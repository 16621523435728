import classes from './Button.module.scss';

const Button = (): JSX.Element => {
  return (
    <a
      href='https://calendly.com/junglesmith'
      target='_blank'
      className={classes['btn']}
      rel='noreferrer'
    >
      Book a call
    </a>
  );
};

export default Button;
