import Hyes from '../icons/Hyes';
import Twitter from '../icons/Twitter';
import classes from './Footer.module.scss';

const Footer = (): JSX.Element => {
  const year: number = new Date().getFullYear();

  return (
    <footer className={classes['footer']}>
      <div className={classes['logo-container']}>
        <Hyes />
      </div>

      <p className={classes['desc']}>
        © {year} Hyes Capital. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
