import GuiInu from '../icons/GuiInu';
import Harborgate from '../icons/Harborgate';
import Jungle from '../icons/Jungle';
import Rafflor from '../icons/Rafflor';
import SeedZ from '../icons/SeedZ';

export interface ProjectModel {
  id: string;
  title: string;
  image: JSX.Element;
  desc: string;
  areas: string[];
}

export const projectsData: ProjectModel[] = [
  {
    id: 'p1',
    title: 'Gui Inu',
    image: <GuiInu />,
    desc: 'Gui Inu, the premier token on Aptos, was developed entirely by our team. Our top-notch process has made Gui Inu the biggest memecoin on the Aptos blockchain. ',
    areas: ['Token Dev', 'Blockchain Development', 'Marketing'],
  },
  {
    id: 'p2',
    title: 'Harborgate',
    image: <Harborgate />,
    desc: 'Top token launchpad protocol on aptos for P2P community token trade, no-code token and launchpad creation. Also for running token airdrops, created for aptos monkeys',
    areas: ['DApp', 'Contract Development', 'Blockchain Development'],
  },
  {
    id: 'p3',
    title: 'The Jungle',
    image: <Jungle />,
    desc: 'We built the first staking tool and community reward platform on Aptos for Aptos Monkeys, now used by some of the best projects on Aptos holding more than 1M$ of assets. ',
    areas: ['DApp', 'Blockchain Development'],
  },
  {
    id: 'p4',
    title: 'Rafflor',
    image: <Rafflor />,
    desc: 'We built the first NFT raffle system on Aptos for Aptos Monkeys.',
    areas: ['DApp', 'Blockchain Development'],
  },
  {
    id: 'p5',
    title: 'Graveyard',
    image: <GuiInu />,
    desc: 'We created a social engagement application living on telegram.',
    areas: ['DApp', 'Telegram bot'],
  },
  {
    id: 'p6',
    title: 'SeedZ',
    image: <SeedZ />,
    desc: 'We created a reward token for aptos monkeys.',
    areas: ['Token dev', 'Blockchain dev'],
  },
];
