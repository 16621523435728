import './App.scss';
import RootLayout from './pages/Layout';

// const router = createBrowserRouter([
//   {
//     path: '',
//     element: <RootLayout />,
//     children: [{ index: true, element: <HomePage /> }],
//   },
// ]);

const App = () => {
  return <RootLayout />;
};

export default App;
