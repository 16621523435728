import classes from './icon.module.scss';

const Rafflor = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
    >
      <path
        d='M55.6638 61.9821C55.5766 61.9821 55.4906 61.9944 55.4045 61.9944C46.5854 61.9944 37.7666 61.9963 28.9482 62C28.8383 61.9984 28.7299 61.9743 28.6297 61.9291C28.5296 61.8839 28.4398 61.8187 28.3659 61.7374C27.5064 60.9438 26.6334 60.1637 25.7717 59.3724C24.7323 58.4168 23.7007 57.4533 22.6635 56.4955C21.443 55.3674 20.2221 54.24 19.0009 53.1134C17.9782 52.1667 16.9623 51.2134 15.9374 50.2678C13.9814 48.4632 12.0233 46.6604 10.0629 44.8594C9.74769 44.5688 9.42807 44.2838 9.11177 43.9944C9.0716 43.9518 9.03426 43.9066 9 43.8591C9.06259 43.7764 9.11175 43.6948 9.17993 43.6244C9.32001 43.4754 9.46231 43.3264 9.60686 43.1773C12.9487 39.8467 16.2901 36.5168 19.6312 33.1876C20.4091 32.4119 21.1811 31.6296 21.9471 30.8405C22.0424 30.7403 22.158 30.6617 22.2862 30.6098C22.4144 30.558 22.5521 30.5341 22.6903 30.5398C24.6276 30.5443 26.5649 30.5443 28.5022 30.5398C29.422 30.5398 30.343 30.5477 31.2629 30.5264C34.057 30.4627 36.7193 28.6264 37.8168 26.1262C38.8887 23.6829 38.7568 21.271 37.4212 18.9519C37.0322 18.2757 36.4924 17.7146 35.9179 17.1815C34.9299 16.2661 33.7619 15.7352 32.4654 15.4513C31.9312 15.3417 31.3868 15.2904 30.8415 15.2982H9.01113V2.03032C9.07149 2.02026 9.12964 2.00908 9.18888 2.00126C9.21568 1.99958 9.24252 1.99958 9.26932 2.00126C16.7123 2.00126 24.1548 2.0035 31.597 2.00797C33.7809 2.00797 35.8956 2.45503 37.9409 3.20275C42.0059 4.68478 45.3432 7.15484 47.8759 10.6721C49.6179 13.0863 50.8327 15.8399 51.4413 18.754C51.7957 20.4531 51.9208 22.192 51.8134 23.9244C51.7397 25.4688 51.5063 27.0015 51.1171 28.4979C50.4375 31.0115 49.273 33.2938 47.7418 35.3905C46.4022 37.2065 44.7714 38.7885 42.9156 40.0724C41.0389 41.3867 38.9666 42.3968 36.7752 43.0656C36.5293 43.1416 36.2723 43.184 36.0197 43.2433C35.9794 43.2522 35.9403 43.269 35.8665 43.2925L55.694 61.9162L55.6638 61.9821Z'
        fill='#361C19'
      />
    </svg>
  );
};
export default Rafflor;
