import classes from './icon.module.scss';

const Jungle = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='66'
      height='64'
      viewBox='0 0 66 64'
      fill='none'
    >
      <path
        d='M45.0004 10.9984L54.0002 19.9982L48.0004 22.9981L60.0001 34.9978L51.0003 37.9977L63 49.9974H36.0007M42.0005 58.9972V49.9974M21.001 34.9978L15.0012 28.9979M21.001 31.9979L27.0009 25.998M21.001 58.9972V19.9982M14.4735 43.9828C13.161 43.9059 11.8812 43.5424 10.7242 42.9178C9.56725 42.2932 8.56118 41.4227 7.77679 40.3675C6.9924 39.3123 6.44874 38.0981 6.18405 36.8102C5.91936 35.5223 5.94007 34.1921 6.24472 32.913C5.156 32.0081 4.29629 30.8589 3.73555 29.5589C3.1748 28.259 2.92887 26.845 3.01774 25.4321C3.10662 24.0191 3.52779 22.6471 4.24701 21.4277C4.96622 20.2082 5.96316 19.1758 7.1567 18.4144C6.11084 16.5564 5.75859 14.3874 6.16271 12.294C6.56683 10.2005 7.70107 8.31849 9.36334 6.98327C11.0256 5.64805 13.1079 4.94636 15.2393 5.0032C17.3707 5.06005 19.4126 5.87174 21.0014 7.29367C22.5905 5.87399 24.6317 5.06422 26.7619 5.00848C28.8921 4.95274 30.9729 5.65464 32.6341 6.98927C34.2953 8.32389 35.429 10.2046 35.8335 12.2968C36.238 14.389 35.887 16.5568 34.843 18.4144C36.0373 19.1751 37.035 20.2072 37.7549 21.4265C38.4748 22.6459 38.8965 24.018 38.9856 25.4312C39.0748 26.8443 38.829 28.2586 38.2681 29.5587C37.7072 30.8589 36.8472 32.0082 35.758 32.913C36.0729 34.2354 36.0842 35.6119 35.791 36.9392C35.4978 38.2666 34.9077 39.5102 34.0651 40.5769C33.2224 41.6435 32.1491 42.5054 30.9257 43.0979C29.7023 43.6904 28.3605 43.998 27.0012 43.9978H15.0015L14.4735 43.9828Z'
        stroke='#361C19'
        strokeWidth='5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default Jungle;
