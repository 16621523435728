import ActionButton from '../../../../components/action-button/ActionButton';
import ServiceList from '../../../../components/service/ServiceList';
import classes from './Mid.module.scss';

const MidSection = (): JSX.Element => {
  return (
    <section className={classes['section']}>
      <ActionButton>Services</ActionButton>

      <p className={classes['purpose']}>
        We focus on delivering a range of IT services to blockchain companies,
        including but not limited to:
      </p>

      <ServiceList />
    </section>
  );
};

export default MidSection;
