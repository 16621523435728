import BottomSection from './sections/bottom/Bottom';
import LandingSection from './sections/landing/Landing';
import MidSection from './sections/mid/Mid';

const HomePage = (): JSX.Element => {
  return (
    <>
      <LandingSection />
      <MidSection />
      <BottomSection />
    </>
  );
};

export default HomePage;
