import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import HomePage from './home/Home';

const RootLayout = (): JSX.Element => {
  return (
    <>
      <Header />

      <main>
        <HomePage />
      </main>

      <Footer />
    </>
  );
};

export default RootLayout;
