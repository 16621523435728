import Button from '../button/Button';
import Logo from '../icons/Logo';
import classes from './Header.module.scss';

const Header = (): JSX.Element => {
  return (
    <header className={classes['header']}>
      <div className={classes['logo']}>
        <Logo />
      </div>

      <Button />
    </header>
  );
};

export default Header;
