import Service from './Service';
import classes from './ServiceList.module.scss';
import { servicesData } from './service.util';

const ServiceList = (): JSX.Element => {
  return (
    <ul className={classes['list']}>
      {servicesData.map((service) => (
        <Service key={service.id} service={service} />
      ))}
    </ul>
  );
};

export default ServiceList;
