import ActionButton from '../action-button/ActionButton';
import Button from '../button/Button';
import classes from './StartWithUs.module.scss';

const StartWithUs = (): JSX.Element => {
  return (
    <div className={classes['container']}>
      <ActionButton>Start with us</ActionButton>

      <p className={classes['desc']}>Lets help you on your next project!</p>

      <div className={classes['just-book']}>Book a call to work with us</div>

      <Button />
    </div>
  );
};

export default StartWithUs;
