import classes from './icon.module.scss';

const Blockchain = (): JSX.Element => {
  return (
    <svg
      className={classes['svg']}
      xmlns='http://www.w3.org/2000/svg'
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
    >
      <path
        d='M40 30V15L27.5 7.96899M20.5 7.96899L8 15V30M11 34.688L24 42L32 37.5L37 34.688M24 17L27 18.75L30 20.5V27.5L27 29.25L24 31L21 29.25L18 27.5V20.5L21 18.75L24 17ZM24 17V9.99999M30 27L37 31M18 27L11 31'
        stroke='#43C2CD'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24 10C25.6569 10 27 8.65685 27 7C27 5.34315 25.6569 4 24 4C22.3431 4 21 5.34315 21 7C21 8.65685 22.3431 10 24 10Z'
        stroke='#43C2CD'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 36C9.65685 36 11 34.6569 11 33C11 31.3431 9.65685 30 8 30C6.34315 30 5 31.3431 5 33C5 34.6569 6.34315 36 8 36Z'
        stroke='#43C2CD'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M40 36C41.6569 36 43 34.6569 43 33C43 31.3431 41.6569 30 40 30C38.3431 30 37 31.3431 37 33C37 34.6569 38.3431 36 40 36Z'
        stroke='#43C2CD'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Blockchain;
